import { request } from '@/utils'
// 获取机场节点列表
export const get_list = (params) => request.get(`/api/airportnode/`,{params})
// 添加机场节点
export const add_info= data =>request.post(`/api/airportnode/`,data)
// 编辑机场节点
export const edit_info= data =>request.patch(`/api/airportnode/${data.id}/`,data)
// 删除机场节点
export const del_info= id =>request.delete(`/api/airportnode/${id}`)
// 获取节点信息
export const get_info = id => request.get(`/api/airportnode/${id}/`)
