<template>
  <div :is="layout">
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <v-text-field
            @keypress.enter="doGet(1)"
            v-model="search_info.group"
            :label="
              $t(
                module_type === 0
                  ? 'airport.NodeTitle'
                  : 'airport.securityNodeTitle'
              )
            "
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn @click="doGet(1)" large color="accent">{{
            $t("search")
          }}</v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :server-items-length="list_count"
        :headers="headers"
        :items="desserts"
        :items-per-page="10"
        :loading-text="$t('data_loading')"
        :loading="table_loading"
        @pagination="changePagination"
        class="elevation-1"

        :footer-props="{
          showFirstLastPage: true,
        }"
      >
        <template v-slot:item.type="{item}">
          {{get_airport_node_type(item.type)}}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              {{
              $t(
              module_type === 0
              ? "airport.airportNode"
              : "airport.securityNode"
              )
              }}</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
        </template>
        <!-- <template v-slot:[`item.remote_stand`]="{ item }">
          <div v-if="item.remote_stand == '1'">是</div>
          <div v-if="item.remote_stand == '0'">否</div>
        </template> -->
        <template v-slot:[`item.Action`]="{ item }">
          <v-btn @click="edit_open(item)" small color="accent" text
            >{{$t('edit')}}
          </v-btn>
          <v-btn @click="del(item)" small color="accent" text>{{$t('delect')}}</v-btn>
        </template>
      </v-data-table>
    </v-container>
    <v-btn @click="add_open" color="accent" fab class="float-btn">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title>{{
          $t(edit_mode ? "airport.edit_Node" : "airport.add_Node")
        }}</v-card-title>
        <v-container class="pd-30">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="info.name"
                :label="$t('airport.name')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="info.lat"
                :label="$t('airport.lat')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="info.lng"
                :label="$t('airport.lng')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                      :items="[{value: 0, text: '普通节点'}, {value: 1, text: '机位节点'}, {value: 2, text: '交汇节点'}, {value: 3, text: '降落起飞节点'}]"
                v-model="info.type"
                :label="$t('airport.type')"
              ></v-select>
            </v-col>
<!--            <v-col cols="6">-->
<!--              <v-text-field-->
<!--                v-model="info.group"-->
<!--                :label="$t('airport.group')"-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-text-field-->
<!--                v-model="info.order"-->
<!--                :label="$t('airport.order')"-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-text-field-->
<!--                v-model="info.attach"-->
<!--                :label="$t('airport.attach')"-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-text-field-->
<!--                v-model="info.road_type"-->
<!--                :label="$t('airport.road_type')"-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit" color="accent">{{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { get_list, add_info, edit_info, del_info } from "@/api/airportNode.js";
// import { get_list as get_node_type_list } from '@/api/nodetype'
import {
  get_support_vehicle_all_path_data,
  add_support_vehicle_info,
  edit_support_vehicle_info,
  del_support_vehicle_info,
} from "@/api/airport_security/";

export default {
  data: () => ({
    layout: null,
    desserts: [],
    search_info: {
      pageindex: 1,
      pagesize: 10,
      group:"",
      module_type: 0,
    },
    totalPage: null,
    module_type: null,
    edit_mode: false,
    node_type: [],
    table_loading: false,
    dialog: false,
    list_count: 0,
    info: {},
    add_loading: false,
  }),
  methods: {
    changePagination(data) {
      this.search_info.pageindex = data.page;
      this.search_info.pagesize = data.itemsPerPage;
      // this.doGet();
      this.minusPage(this.list_count)
    },
    minusPage(data){
      let totalPage = Math.ceil((data) / this.search_info.pagesize);
      let pageindex =this.search_info.pageindex > totalPage? totalPage: this.search_info.pageindex;
      this.search_info.pageindex = pageindex < 1 ? 1 : pageindex;
      this.doGet();
    },
    del(data) {
      if (this.module_type === 0) {
        this.$confirm({
           title: this.$t('confirm_del'),
          content: this.$t("airport.delete_node"),
          okText: this.$t('confirm'),
          cancelText: this.$t('cancel')
        }).then((dialog) => {
          del_info(data.id).then((res) => {
          //  this.doGet();
          this.minusPage(this.list_count-1)
          });
        });
      } else if(this.module_type === 1){
        this.$confirm({
          title: this.$t('confirm_del'),
          content: this.$t("airport.delete_node"),
          okText: this.$t('confirm'),
          cancelText: this.$t('cancel')
        }).then((dialog) => {
          del_support_vehicle_info(data.id).then((res) => {
            //  this.doGet();
          this.minusPage(this.list_count-1)
          });
        })
        ;
      }
    },
    edit_open(data) {
      this.edit_mode = true;
      // this.info = JSON.parse(JSON.stringify(data));

      this.info = {
        // node_type: "",
        // node_name: "",
        // transverse_coordinate: "",
        // longitudinal_coordinate: "",
        id: data.id,
        name: data.name,
        lat: data.lat,
        lng: data.lng,
        type: data.type,
        airport_id: this.search_info.airport_id,
        model_type: this.model_type,
        // group: "",
        // order: "",
        // road_type: "",
        // attach: "",
        // match_model: "",
        // remote_stand: "",
        // id: this.search_info.id,
      };
      this.dialog = true;
    },
    add_open() {
      this.edit_mode = false;
      this.info = {
        // node_type: "",
        // node_name: "",
        // transverse_coordinate: "",
        // longitudinal_coordinate: "",
        name: "",
        lat: "",
        lng: "",
        type: "",
        airport_id: this.search_info.airport_id,
        model_type: this.model_type,
        // group: "",
        // order: "",
        // road_type: "",
        // attach: "",
        // match_model: "",
        // remote_stand: "",
        // id: this.search_info.id,
      };
      this.dialog = true;
    },
    get_airport_node_type(type) {
      switch(type) {
        case 0:
          return '普通节点'
        case 1:
          return '机位节点'
        case 2:
          return '交汇节点'
        case 3:
          return '降落起飞节点'
        default:
          return '位置类型'
      }
    },
    doGet(pageindex = this.search_info.pageindex) {
      this.search_info.pageindex = pageindex;
      this.table_loading = true;
      const closeLoading = this.$loading()
       if(this.module_type===1){
        get_support_vehicle_all_path_data(this.search_info)
          .then((res) => {
            this.desserts = res.results;
            this.list_count = res.count;
          //   if (Math.ceil(res.count / this.search_info.pagesize)< this.search_info.pageindex -1) {
          //   this.search_info.pageindex--
          // }
          })
          .finally(() => {
            this.table_loading = false;
            closeLoading()
          });
      }
        else if (this.module_type === 0) {
        get_list(this.search_info)
          .then((res) => {
            this.desserts = res.results;
            this.list_count = res.count;
          })
          .finally(() => {
            this.table_loading = false;
            closeLoading()
          });
      }
    },
    submit() {
      this.add_loading = true;
      if (this.edit_mode) {
        if (this.module_type === 0) {
          edit_info(this.info)
            .then((res) => {
              this.$toast({
                title: this.$t('success'),
                message: this.$t("airport.edit_success_node"),
              });
              this.dialog = false;
            })
            .finally(() => {
              this.add_loading = false;
              this.doGet();
            });
        } else if(this.module_type===1){
          edit_support_vehicle_info(this.info)
            .then((res) => {
              this.$toast({
                title: this.$t('success'),
                message:this.$t("airport.edit_success_node"),
              });
              this.dialog = false;
            })
            .finally(() => {
              this.add_loading = false;
              this.doGet();
            });
        }
      } else {
        if (this.module_type === 0) {
          add_info(this.info)
            .then((res) => {
              this.$toast({
                title: this.$t('success'),
                message: this.$t("airport.add_success_node"),
              });
              this.dialog = false;
            })
            .finally(() => {
              this.add_loading = false;
              this.doGet();
            });
        } else if(this.module_type===1){
          add_support_vehicle_info(this.info)
            .then((res) => {
              this.$toast({
                title: this.$t('success'),
                message: this.$t("airport.add_success_node"),
              });
              this.dialog = false;
            })
            .finally(() => {
              this.add_loading = false;
              this.doGet();
            });
        }
      }
    },
  },
  mounted() {
    // 获取机场节点数据
    // if(this.$route.params.selectNode==0){
    //     get_node_type_list({pagesize: 999}).then(res => {
    //   this.node_type=res.results
    // })
    this.layout = this.$route.meta.use_layout
    this.search_info.airport_id = this.$route.params?.id ;
    this.module_type=this.$route.params?.selectNode * 1
    this.search_info.module_type=this.$route.params?.selectNode * 1
    this.doGet();
  },
  computed: {
    headers() {
      return [
        // {
        //   text: this.$t("airport.node_type"),
        //   align: "center",
        //   value: "node_type",
        //   sortable: true,
        // },
        // {
        //   text: this.$t("airport.node_name"),
        //   align: "center",
        //   value: "node_name",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("airport.transverse_coordinate"),
        //   align: "center",
        //   value: "transverse_coordinate",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("airport.longitudinal_coordinate"),
        //   align: "center",
        //   value: "longitudinal_coordinate",
        //   sortable: false,
        // },

        {
          text: this.$t("airport.name"),
          align: "center",
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("airport.lat"),
          align: "center",
          value: "lat",
          sortable: false,
        },
        {
          text: this.$t("airport.lng"),
          align: "center",
          value: "lng",
          sortable: false,
        },

        {
          text: this.$t("airport.type"),
          align: "center",
          value: "type",
          sortable: false,
        },
        // {
        //   text: this.$t("airport.group"),
        //   align: "center",
        //   value: "group",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("airport.order"),
        //   align: "center",
        //   value: "order",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("airport.attach"),
        //   align: "center",
        //   value: "attach",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("airport.road_type"),
        //   align: "center",
        //   value: "road_type",
        //   sortable: false,
        // },
        {
          text: this.$t("airport.Action"),
          align: "center",
          value: "Action",
          sortable: false,
        },
        // {
        //   text: this.$t("airport.match_model"),
        //   align: "center",
        //   value: "match_model",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("airport.remote_stand"),
        //   align: "center",
        //   value: "remote_stand",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("airport.airport_id"),
        //   align: "center",
        //   value: "airport_id",
        //   sortable: true,
        // },
      ];
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/css/global.styl';
</style>
